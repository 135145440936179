@import "@/theme.scss";


































// @import './../theme.scss';10：30
.home {
  width: 100%;
  height: 100%;
  .el-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-header {
      height: 60px;
      @include headertBg()
    }
    .el-main {
      flex: 1;
      padding: 0 !important;
      margin: 0 !important;
      @include themeBg()
    }
    .el-footer {
      height: 60px;
    }
  }
}
