@import "@/theme.scss";
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.mysubmenu {
  background: #191919 !important;
  .el-menu--popup {
    min-width: 120px !important;
  }
  ul {
    background: #191919 !important;
  }
  li {
    background: #191919 !important;

    a {
      color: #fff;
    }
  }
  .is-active {
    background: #0ba3f0 !important;
  }
  li:hover {
    // background: #0ba3f0 !important;
  }
}
.headerbox {
  height: 60px;
  color: #ededed;
  // background: black;
  background: #191919;

  display: flex;
  align-items: center;
  justify-content: space-between;
  .head_l {
    display: flex;
    align-items: center;
    flex: 10;
    .el-header {
      padding: 0 !important;
    }
    .logo {
      flex: 1;
      img {
        width: 100px;
        // height: 100px;
        margin-top: 20px;
      }
    }
    .menu {
      flex: 6;
      .el-menu {
        // background: black;
        background: #191919;
      }
      .el-menu-demo {
        display: flex;
        color: #fff;
        .activeLabel {
          background: #0ba3f0;
        }
      }
      .el-submenu__title {
        color: #fff;
        font-size: 24px;
        padding: 0 20px;
        width: 120px;
        .el-submenu__icon-arrow {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .el-menu.el-menu--horizontal {
        border: none !important;
        .el-menu-item {
          color: #fff !important;
          font-size: 24px !important;
          // background: #191919 !important;
        }
      }
      .el-menu--horizontal > .el-menu-item.is-active {
        color: #fff;
      }
      .el-menu-item:hover {
        // background: #222222 !important;
      }
      .el-menu-item > a {
        color: #fff !important;
        text-decoration: none;
      }
      .router-link-exact-active {
        color: #fff !important;
      }
    }
  }
  .head_r {
    flex: 2;
    max-width: 300px !important;
    height: 34px !important;
    display: flex;
    .user {
      width: 140px;
      line-height: 34px;
      .name {
        color: #fdca64;
      }
    }
    .autoinput {
      width: 204px !important;
      height: 34px !important;
      line-height: 34px;
      .el-input,
      .el-input--suffix {
        .el-input__inner {
          background-color: #222222 !important;
          color: #fff !important;
          border: none;
        }
      }
      .iconcolor {
        color: #0ba3f0 !important;
        font-weight: 900;
        font-size: 20px;
      }
    }
    .my-autocomplete {
      li {
        // line-height: normal;
        line-height: 20px;
        // padding: 7px;
        padding: 7px 0;

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .addr {
          font-size: 12px;
          color: #b4b4b4;
        }

        .highlighted .addr {
          color: #ddd;
        }
      }
    }
    .search {
      margin-right: 45px;
      input {
        border-radius: 5px;
      }
    }
  }
}
